import request from "@/utils/request";
import ENDPOINT from "@/config/endpoint";
const qs = require("qs");

export async function fetchPosts(params) {
  return await request({
    url: ENDPOINT.POSTS,
    method: "get",
    params,
  });
}

export async function fetchPostsById(id) {
  return await request({
    url: `${ENDPOINT.POSTS}/${id}`,
    method: "get",
  });
}

export async function searchPosts(params) {
  return await request({
    url: ENDPOINT.SEARCH_POSTS,
    method: "get",
    params,
  });
}

export async function createPosts(data) {
  return await request({
    url: ENDPOINT.POSTS,
    method: "post",
    data: qs.stringify(data),
  });
}

export async function updatePosts(id, data) {
  return await request({
    url: `${ENDPOINT.POSTS}/${id}`,
    method: "put",
    data: qs.stringify(data),
  });
}

export async function deletePosts(id) {
  return await request({
    url: `${ENDPOINT.POSTS}/${id}`,
    method: "delete",
  });
}
