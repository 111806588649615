import request from "@/utils/request";
import ENDPOINT from "@/config/endpoint";

export async function upload(data) {
  return await request({
    url: ENDPOINT.UPLOAD,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    data,
  });
}
